<template>
  <!-- 吸顶时候出现的 不用占位-->
  <div 
    v-if="visibleComp"
    class="free-shipping free-shipping__sticky-block is-fixed" 
    :class="{
      'free-shipping__sticky-block--new': isNewUserStyle
    }"
    :style="{
      top: stickyTopPx,
      height: isNewUserStyle ? '.8533rem' : '',
      backgroundColor: containerStyle?.backgroundColor || '' 
    }"
  >
    <swiper-container
      ref="swiperReferenceSticky" 
      class="free-shipping__fixed-swiper"
      init="false"
    >
      <!-- 旧样式 -->
      <template v-if="!isNewUserStyle">
        <swiper-slide
          v-for="(item, index) in showItems"
          :key="`slide-${item.type}-${item.hrefType}-${index}`"
          class="swiper-no-swiping"
        >
          <FreeShippingList
            class="free-shipping is-fixed" 
            :items="[item]"
            :is-sticky="true"
            :custom-component-name="customComponentName"
            :language="language" 
            :get-analysis-data="getAnalysisData" 
            v-bind="injectData"
            :is-show-shipping-coupon="isShowShippingCoupon"
            @clickCoupon="handleTypeDrawerData"
            @getContainerStyle="getContainerStyle"
          />
        </swiper-slide>
      </template>
      <!-- 新样式 无券 -->
      <swiper-slide
        v-if="isNewUserStyle && showItems.length > 0"
        key="newUserCoupon-sticky"
        class="swiper-no-swiping"
      >
        <FreeShippingList 
          class="free-shipping is-fixed" 
          :style="{ 'height': '.8533rem' }"
          :is-sticky="true"
          :show-items="showItems"
          :is-new-user-style="isNewUserStyle"
          :get-analysis-data="getAnalysisData" 
          :language="language" 
          :is-show-shipping-coupon="isShowShippingCoupon"
          v-bind="injectData"
          @clickCoupon="handleTypeDrawerData"
        />
      </swiper-slide>
      <!-- 新样式 有券 -->
      <swiper-slide
        v-if="isNewUserStyle && isHasNewUserCoupon.length > 0"
        key="newUserCoupon-sticky-new"
        class="swiper-no-swiping"
      >
        <FreeShippingList 
          class="free-shipping is-fixed" 
          :style="{ 'height': '.8533rem' }"
          :is-sticky="true"
          :is-has-new-user-coupon="isHasNewUserCoupon.length > 0"
          :is-new-user-style="isNewUserStyle"
          :get-analysis-data="getAnalysisData" 
          :items="isHasNewUserCoupon"
          :is-show-shipping-coupon="isShowShippingCoupon" 
          :language="language" 
          v-bind="injectData"
          @clickCoupon="handleTypeDrawerData"
        />
      </swiper-slide>
    </swiper-container>
  </div>
</template>
  
<script>
import { register } from 'swiper/element'
import { Autoplay } from 'swiper/modules'
typeof window !== 'undefined' && register()
export default {
  name: 'StickyFreeShipping',
  components: {
    FreeShippingList: () => import(/* webpackChunkName: "free-shipping-list-comp" */'./FreeShippingList.vue'),
    // FreeShippingList,
  },
  props: {
    showItems: {
      type: Array,
      default() {
        return []
      }
    },
    language: {
      type: Object,
      default() {
        return {}
      }
    },
    getAnalysisData: {
      type: Function,
      default() {
        return () => {}
      }
    },
    injectData: {
      type: Object,
      default() {
        return {}
      }
    },
    stickyTop: {
      type: Number,
      default() {
        return 0
      }
    },
    handleTypeDrawerData: {
      type: Function,
      default() {
        return () => {}
      }
    },
    activeIndex: {
      type: Number,
      default() {
        return 0
      }
    },
    isNewUserStyle: {
      type: Boolean,
      default() {
        return false
      }
    },
    isHasNewUserCoupon: {
      type: Array,
      default() {
        return []
      }
    },
    getSelfCoupon: {
      type: Object,
      default() {
        return {}
      }
    },
    isShowShippingCoupon: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      visibleComp: false,
      staticIndex: this.activeIndex,
      containerStyle: {}
    }
  },
  computed: {
    stickyTopPx () {
      return `${this.stickyTop || 0}px`
    },
    options() {
      return {
        direction: 'vertical',
        loop: true,
        initialSlide: this.activeIndex,
        modules: [Autoplay],
        noSwiping: true,
        noSwipingClass: 'swiper-no-swiping',
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        },
        unAutoInit: true,
        on: {
          activeIndexChange: (swiper) => {
            // 保存当前轮播图的activeIndex, 下次轮播开始的位置
            this.staticIndex = swiper.realIndex
          }
        }
      }
    },
    // 埋点， 吸顶自定义的组件名称
    customComponentName() {
      return 'policy_floating'
    },
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    show() {
      this.visibleComp = true
      this.$nextTick(() => {
        // 手动初始化Swiper
        this.initSwipper('swiperReferenceSticky')
      })
    },
    hidden() {
      this.visibleComp = false
      // 传递当前的activeIndex
      return this.staticIndex
    },
    update(params) {
      this.staticIndex = params?.activeIndex
    },
    initSwipper(ref) {
      // swiper element
      const swiperEl = this.$refs[ref]
      if (!swiperEl) return
      Object.assign(swiperEl, this.options)
      swiperEl.initialize()
    },
    getContainerStyle(style) {
      if (this.containerStyle.backgroundColor) return //  减少重复赋值
      this.containerStyle = style
    }
  },
}
</script>
  
<style lang="less">
.free-shipping__fixed-swiper {
  position: relative;
  height: 100%;
  .swiper-slide {
    height: 100% !important; /* stylelint-disable-line declaration-no-important */
  }
}
.free-shipping__sticky-block--new {
  border-top: 0.5px solid #E5E5E5;
}
.free-shipping.free-shipping__sticky-block.is-fixed {
  background-color: transparent;
}
</style>
  
